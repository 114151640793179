<!-- =========================================================================================
    File Name: Error500.vue
    Description: 500 Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="ReportWrapper">
        <div class="filterReport">

            <div style="display: flex; justify-content: space-around">
                <vs-input value="Submit" icon-pack="feather" icon-no-border placeholder="Miru Sayısı" v-model="totalMiruCount"/>
                <vs-input value="Submit" icon-pack="feather" icon-no-border placeholder="Scanner Sayısı" v-model="totalScannerCount"/>
                <vs-select v-model="selectedMerchant">
                    <vs-select-item :key="index" :value="item.merchant_id" :text="item.merchant_name"
                                    v-for="(item,index) in merchantList"/>
                </vs-select>
                <vs-select class="selectExample" v-model="selectedStore">
                    <vs-select-item :key="index" :value="item.id" :text="item.name"
                                    v-for="(item,index) in merchantStoreList"/>
                </vs-select>
                <vs-button @click="createReport">Raporu Getir</vs-button>
            </div>
        </div>
        <div class="repornoChangetLogo">
            <img src="../../assets/images/logo/buybuddy2.png" width="200"/>
        </div>
        <div class="reportTitleBig">{{ $t("dailyStoreReport") }}</div>
        <div class="storeInfo">
            <h4 style="margin-bottom: 10px; font-weight: bolder">{{ $t("storeInformations") }}</h4>
            <span style="font-weight: bold">{{ $t("storeName") }} :
                <span style="font-weight: 500">{{this.selectedStoreName}}</span>
            </span>
            <span style="font-weight: bold">{{ $t("reportDate") }} :  <span
                    style="font-weight: 500">{{this.reportDay}}</span> </span>
            <span style="font-weight: bold">{{ $t("miruCount") }} :  <span style="font-weight: 500">{{this.totalMiruCount}}</span></span>
            <span style="font-weight: bold">{{ $t("scannerCount") }} : <span style="font-weight: 500"> {{this.totalScannerCount}}</span></span>

        </div>
        <div class="reportSecurity">
            <div class="reportTitleM">{{ $t("securityReport") }}</div>
            <div class="reportSecurityTable">
                <vx-card class="MostCardStyle" :title="$t('mostAlarmedHours')">
                    <vue-apex-charts class="animated fadeIn " id="mostAlarmedHourLoadingWrapper" type="heatmap"
                                     height="300" :options="chartOptions"
                                     :series="series"></vue-apex-charts>
                </vx-card>
                <vx-card class="MostCardStyle Limit5Table" :title="$t('mostAlarmedItems')">
                    <div class="tableWrapper-scroll">
                        <vs-table id="mostAlarmedItemLoadingWrapper" :data="MostAlarmedItemList">
                            <template slot="thead">

                                <vs-th style="min-width: 150px">{{ $t("itemName") }}</vs-th>
                                <vs-th>{{ $t("alarmCount") }}</vs-th>
                            </template>
                            <template slot-scope="{data}">
                                <vs-tr class="animated fadeIn " :key="index" v-for="(item, index) in data">

                                    <vs-td>
                                        {{ item.item_name }}
                                    </vs-td>
                                    <vs-td>
                                        {{ item.alarm_count }}
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </vx-card>
                <vx-card class="MostCardStyle Limit4Table" :title="$t('mostAlarmedZones')">
                    <div class="tableWrapper-scroll">
                        <vs-table id="mostAlarmedZoneLoadingWrapper" :data="MostAlarmedZoneList">
                            <template slot="thead">

                                <vs-th>{{ $t("zoneName") }}</vs-th>
                                <vs-th>{{ $t("alarmCount") }}</vs-th>
                            </template>
                            <template slot-scope="{data}">
                                <vs-tr class="animated fadeIn " :key="index" v-for="(item, index) in data">

                                    <vs-td>
                                        {{ item.zone_name }}
                                    </vs-td>
                                    <vs-td>
                                        {{ item.alarm_count }}
                                    </vs-td>
                                </vs-tr>


                            </template>
                        </vs-table>
                    </div>
                </vx-card>
            </div>
            <div class="reportSecurityTable">
           <!--     <vx-card id="topPerson" style="height: 500px;" class="MostCardStyle Limit5Table"
                         :title="$t('topPerson')">
                    <div class="tableWrapper-scroll">
                        <div class="topPersonDes">{{ $t("topPersonDes")}}</div>
                        &lt;!&ndash; <vs-table id="mostAlarmedPersonLoadingWrapper" >
                             <template slot="thead">

                                 <vs-th>{{ $t("PersonName") }}</vs-th>
                                 <vs-th>{{ $t("alarmCount") }}</vs-th>
                             </template>
                             <template>
                                 <vs-tr class="animated fadeIn " >

                                     <vs-td>

                                     </vs-td>
                                     <vs-td >

                                     </vs-td>
                                 </vs-tr>
                             </template>
                         </vs-table>&ndash;&gt;
                        <div class="yakinda">Bu Tablo Yakında Açılacaktır</div>
                    </div>
                </vx-card>-->
                <vx-card class="MostCardStyle2" style="width: 100% !important;" :title="$t('comparedToThePreviousDayAlarmed')">
                    <div style="display: flex">

                        <div class="chartDes">
                                              <span class="chartDesFont">{{ $t("comparedToThePreviousDayAlarmed")}}<span v-if="this.alarmIncreased"> {{ $t("increased") }}</span>
                                                 <span v-if="this.alarmDecreased"> {{ $t("decreased") }}</span>
                                                 <span v-if="this.noChangeAlarm">{{$t("noChange") }}</span>
                                                 <span>{{ $t("comparedToThePreviousDayPickedUpDes2") }}</span>

                                            </span>
                            <div class="arrowRateWrapperAlarm">
                                <div>
                                    <span class="rateFont arrowRed" v-if="this.alarmIncreased"> {{this.alarmRate}} % </span>
                                    <span class="rateFont arrowGreen" v-if="this.alarmDecreased"> {{this.alarmRate}} % </span>
                                    <span class="rateFont arrowGreen" v-if="this.noChangeAlarm"> {{this.alarmRate}} % </span>
                                </div>
                                <div class="arrowArea ">
                                    <i class="material-icons arrowRed" v-if="this.alarmIncreased">arrow_upward</i>
                                    <i class="material-icons arrowGreen" v-if="this.alarmDecreased">arrow_downward</i>
                                </div>

                            </div>
                        </div>

                        <div style="height: 350px; margin: auto" class="tableWrapper-scroll">
                            <div class="alarmChartCountLabel">
                                <span class="lastDay">{{ $t("lastDay") }}: {{this.alarmBefore}}</span>
                                <span class="today"> {{ $t("today") }} : {{this.alarmLastDay}}</span>
                            </div>
                            <vue-apex-charts type="bar" width="400" height="250"
                                             :options="mixedChartAlarm.chartOptions"
                                             :series="mixedChartAlarm.series"></vue-apex-charts>
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>
        <!-- <div class="reportSecurityStatus">
             <div class="reportTitleM"><span>{{ $t("securityStatus") }}</span></div>
             <div class="reportTitleDes">Dün, 20 adet uyarı veren üründen tekrar aktif hale getirilenler: 5 <br>
                 Risk taşıyanlar listesine eklenenler 15
             </div>
             <div class="reportSecurityTable">
                 <vx-card style="height: 500px;" class="MostCardStyle" :title="$t('securityStatus')">
                     <div style="height: 420px; overflow: scroll" class="tableWrapper-scroll">
                         <div slot="no-body">
                             <vue-apex-charts type="donut" height="300" class="mt-5"
                                              :options="sessionsByDeviceDonut.chartOptions"
                                              :series="sessionsByDeviceDonut.series"/>
                         </div>
                         <ul>
                             <li v-for="deviceData in sessionsByDeviceDonut.analyticsData" :key="deviceData.device"
                                 class="flex mb-3">
                                 <feather-icon :icon="deviceData.icon"
                                               :svgClasses="[`h-5 w-5 stroke-current text-${deviceData.color}`]"></feather-icon>
                                 <span class="ml-2 inline-block font-semibold">{{ deviceData.device }}</span>
                                 <span class="mx-2">-</span>
                                 <span class="mr-4">{{ deviceData.sessionsPercentgae }}</span>
                             </li>
                         </ul>
                     </div>

                 </vx-card>
                 <vx-card class="MostCardStyle2" :title="$t('riskyProductList')">
                     <div style="height: 420px; overflow: scroll" class="tableWrapper-scroll">
                         <vs-table id="riskliÜrünWrapper" :data="RiskProductList">
                             <template slot="thead">

                                 <vs-th>{{ $t("deviceID") }}</vs-th>
                                 <vs-th>{{ $t("itemName") }}</vs-th>
                                 <vs-th>{{ $t("alarmingTime") }}</vs-th>
                                 <vs-th>{{ $t("alarmingZone") }}</vs-th>
                                 <vs-th>{{ $t("currentZone") }}</vs-th>
                             </template>
                             <template slot-scope="{data}">
                                 <vs-tr class="animated fadeIn " :key="index" v-for="(item, index) in data">

                                     <vs-td>{{ item.alarm_count }}</vs-td>
                                     <vs-td>{{ item.item_name }}</vs-td>
                                     <vs-td>{{ item.alarm_count }}</vs-td>
                                     <vs-td>{{ item.alarm_count }}</vs-td>
                                     <vs-td>{{ item.alarm_count }}</vs-td>
                                 </vs-tr>
                             </template>
                         </vs-table>
                     </div>
                 </vx-card>
             </div>
         </div>-->
        <div class="reportProductPerformance">
            <div class="reportTitleM specialTitle">{{ $t("productPerformanceReport") }}</div>
             <div class="reportSecurityTable">
                  <vx-card class="MostCardStyle Limit5Table" :title="$t('mostInteractionedHours')">
                      <vue-apex-charts class="animated fadeIn "  type="heatmap"
                                       height="300" :options="chartOptionsInteraction"
                                       :series="seriesInteraction"></vue-apex-charts>
                  </vx-card>
                  <vx-card class="MostCardStyle Limit5Table" :title="$t('mostInteractionedItems')">
                      <div class="tableWrapper-scroll">
                          <vs-table id="mostPickedUpItemLoadingWrapper" :data="items">
                              <template slot="thead">

                                  <vs-th style="min-width: 200px" >{{ $t("itemName") }}</vs-th>
                                  <vs-th >{{ $t("interactionCount") }}</vs-th>
                              </template>
                              <template slot-scope="{data}">
                                  <vs-tr class="animated fadeIn " :key="index" v-for="(item, index) in data">

                                      <vs-td :data="item.item_name">
                                          {{ item.item_name }}
                                      </vs-td>

                                      <vs-td :data="item.interaction_count">
                                          {{ item.interaction_count }}
                                      </vs-td>
                                  </vs-tr>
                              </template>
                          </vs-table>
                      </div>
                  </vx-card>
                  <vx-card class="MostCardStyle Limit5Table" :title="$t('mostInteractionedZones')">
                      <div class="tableWrapper-scroll">
                          <vs-table id="mostAlarmedZone2LoadingWrapper" :data="zones">
                              <template slot="thead">
                                  <vs-th >{{ $t("zoneName") }}</vs-th>
                                  <vs-th >{{ $t("interactionCount") }}</vs-th>
                              </template>
                              <template slot-scope="{data}">
                                  <vs-tr class="animated fadeIn " :key="index" v-for="(item, index) in data">

                                      <vs-td :data="item.zone_name">
                                          {{ item.zone_name }}
                                      </vs-td>

                                      <vs-td :data="item.interaction_count">
                                          {{ item.interaction_count }}
                                      </vs-td>
                                  </vs-tr>
                              </template>
                          </vs-table>
                      </div>
                  </vx-card>
              </div>
            <div  class="reportTitleM bottomTitle">{{ $t("interestReport") }}</div>
            <div class="reportSecurityTable SpecialArea">

                <vx-card class="MostCardStyle" :title="$t('conversionToBasketPerformance')">
                    <vue-apex-charts type=radialBar height=240
                                     :options="goalOverviewRadialBar.chartOptions"
                                     :series="goalOverviewRadialBar.series"/>
                    <div  class="flex justify-between text-center" slot="no-body-bottom">
                        <div class="w-1/2 border border-solid border-grey-light border-r-0 border-b-0 border-l-0">
                            <p class="mt-4">{{ $t("totalPickedUp") }}</p>
                            <p class="mb-4 text-3xl font-semibold">{{this.totalPickedUp}}</p>
                        </div>
                        <div class="w-1/2 border border-solid border-grey-light border-r-0 border-b-0">
                            <p class="mt-4">{{ $t("totalBasket") }}</p>
                            <p class="mb-4 text-3xl font-semibold">{{this.totalSales}}</p>
                        </div>
                    </div>
                </vx-card>

                <vx-card class="MostCardStyle2" :title="$t('comparedToThePreviousDayPickedUp')">
                    <div style="display: flex">
                        <div class="chartDes">
                                              <span class="chartDesFont">{{ $t("comparedToThePreviousDayPickedUpDes1") }}
                                                 <span v-if="this.pickedUpIncreased"> {{ $t("increased") }}</span>
                                                 <span v-if="this.pickedUpDecreased"> {{ $t("decreased") }}</span>
                                                 <span> {{ $t("comparedToThePreviousDayPickedUpDes2") }}</span>

                                            </span>
                            <div class="arrowRateWrapper">
                                <div>
                                    <span class="rateFont arrowGreen" v-if="this.pickedUpIncreased"> {{this.pickedUpRate}} % </span>
                                    <span class="rateFont arrowRed" v-if="this.pickedUpDecreased"> {{this.pickedUpRate}} % </span>
                                </div>
                                <div class="arrowArea ">

                                    <i class="material-icons arrowGreen" v-if="this.pickedUpIncreased">arrow_upward</i>
                                    <i class="material-icons arrowRed" v-if="this.pickedUpDecreased">arrow_downward</i>
                                </div>

                            </div>
                        </div>

                        <div style="height: 460px; margin: auto" class="tableWrapper-scroll">
                            <div class="pickedUpChartCountLabel">
                                <span class="lastDay">{{ $t("lastDay") }}: {{this.pickedUpBeforeDay}}</span>
                                <span class="today"> {{ $t("today") }} : {{this.pickedUpLastDay}}</span>
                            </div>
                            <vue-apex-charts type="bar" width="400" height="250"
                                             :options="mixedChartPickedUp.chartOptions"
                                             :series="mixedChartPickedUp.series"></vue-apex-charts>
                        </div>


                    </div>
                </vx-card>

            </div>
        </div>

        <!--    <div class="reportStock">
                <div class="reportTitleM">{{ $t("stockReport") }}</div>
                <div class="reportSecurityTable">
                    <vx-card class="MostCardStyle3">
                        <div style="height: 350px;" class="tableWrapper-scroll">
                            <vs-table id="stockLoadingWrapper" :data="stockList">
                                <template slot="thead">
                                    <vs-th>{{ $t("deviceID") }}</vs-th>
                                    <vs-th>{{ $t("itemName") }}</vs-th>
                                    <vs-th>{{ $t("firstStockCount") }}</vs-th>
                                    <vs-th>{{ $t("lastStockCount") }}</vs-th>
                                    <vs-th>{{ $t("stockChange") }}</vs-th>
                                    <vs-th>{{ $t("productShelfTime") }}</vs-th>
                                </template>
                                <template slot-scope="{data}">
                                    <vs-tr class="animated fadeIn " :key="index" v-for="(item, index) in data">
                                        <vs-td>{{ item.alarm_count }}</vs-td>
                                        <vs-td>{{ item.item_name }}</vs-td>
                                        <vs-td>{{ item.alarm_count }}</vs-td>
                                        <vs-td>{{ item.alarm_count }}</vs-td>
                                        <vs-td>{{ item.alarm_count }}</vs-td>
                                        <vs-td>{{ item.alarm_count }}</vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                    </vx-card>
                </div>
                <div class="reportSecurityTable">
                    <vx-card class="MostCardStyle3" :title="$t('comparedToThePreviousDayStock')">
                        <div style="display: flex">
                            <div class="chartDesStock">
                                <span class="chartDesFont">{{ $t("comparedToThePreviousDayStock") }}
                                    <span style="color: #39da8b"> 6 %</span>
                                   <span> {{ $t("increased") }}</span>
                                   <span> {{ $t("decreased") }}</span>
                                </span>
                            </div>
                            <div style="height: 300px; margin: auto" class="tableWrapper-scroll">
                                <div class="stockChartCountLabel">
                                    <span class="lastDay">{{ $t("lastDay") }}: {{this.stockLastDay}}</span>
                                    <span class="today"> {{ $t("today") }} : {{this.stockToday}}</span>
                                </div>
                                <vue-apex-charts type="bar" width="400" height="250"
                                                 :options="mixedChartStock.chartOptions"
                                                 :series="mixedChartStock.series"></vue-apex-charts>
                            </div>

                        </div>
                    </vx-card>
                </div>
            </div>-->
        <div style="margin-bottom: 30px; " class="reportTitleM koronaTitle" >{{ $t("coronaTraceTrack") }}</div>
        <vx-card  style="margin-bottom: 50px; "  :title="$t('gunIcindeDokunulanCihazlar')">

            <div class="tableWrapper">
                <vs-table  :data="itemsAll">
                    <template slot="thead">
                        <vs-th></vs-th>
                        <vs-th >{{ $t("itemName") }}</vs-th>
                        <vs-th >{{ $t("deviceID") }}</vs-th>
                        <vs-th >{{ $t("date") }}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr class="animated fadeIn " :key="index" v-for="(item, index) in data">

                            <vs-td style="max-width: 100px">
                                <img :src="item.meta_data != null ? item.meta_data.image_url :'https://www.cornellstore.com/b2c/img/no_image_available.jpeg'"
                                     class="resize_fit_center" width="80">
                            </vs-td>
                            <vs-td :data="item.item_name">
                                {{ item.item_name }}
                            </vs-td>

                            <vs-td :data="item.identifier">
                                {{ item.identifier }}
                            </vs-td>
                            <vs-td :data="item.inserted_at">
                                {{ item.inserted_at |moment('MMM Do YYYY, h:mm') }}
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </vx-card>
    </div>
</template>

<script type="text/javascript" >
import VueApexCharts from "vue-apexcharts";
import config from "../../../config";
import qs from "qs";
import analyticsData from '../ui-elements/card/analyticsData.js'
import apexChartData from '../../views/charts-and-maps/charts/apex-charts/apexChartData.js';
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'


export default {
  data() {
    return {
      selectedMerchant: 2,
      merchantList: [
        {merchant_name: 'Farfetch', merchant_id: 5},
        {merchant_name: 'MediaMarkt', merchant_id: 2},
        {merchant_name: 'BuyBuddy', merchant_id: 8},

      ],
      selectedStore: 1,
      selectedStoreName: '',
      select3: 2,
      merchantStoreList: [],
      options3: [
        {text: 'Red', value: 1},
        {text: 'Green', value: 2},
        {text: 'Blue', value: 3},
        {text: 'Purple', value: 4},
      ],
      series: [],
      seriesInteraction: [],
      chartOptions: {
        dataLabels: {
          style: {
            colors: ['#fff'],
            height: 20
          }
        },
        plotOptions: {

          heatmap: {
            colorScale: {
              ranges: [{
                from: -50,
                to: 0,
                color: '#fbfbfb',
                name: this.$t("low"),

              },
                {
                  from: 1,
                  to: 3,
                  color: '#f86f79',
                  name: this.$t("medium"),
                },
                {
                  from: 3,
                  to: 6,
                  color: '#ea0d1a',
                  name: this.$t("high"),
                }
              ]
            }
          }
        },
        colors: ["#d20c17"],
      },
      chartOptionsInteraction: {
        dataLabels: {
          style: {
            colors: ['#fff'],
            height: 20
          }
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [{
                from: -50,
                to: 0,
                color: '#fbfbfb',
                name: this.$t("low"),

              },
                {
                  from: 1,
                  to: 40,
                  color: '#d01cff',
                  name: this.$t("medium"),
                },
                {
                  from: 41,
                  to: 100,
                  color: '#b943ff',
                  name: this.$t("high"),
                }
              ]
            }
          }
        },
        colors: ['#951ff2'],
      },
      filters: JSON.parse(localStorage.getItem('filters')),
      MostAlarmedDeviceList: [],
      MostAlarmedItemList: [],
      MostAlarmedZoneList: [],
      MostInteractionItemList: [],
      MostInteractionZoneList: [],
      salesToBasket: [],
      merchant_id: localStorage.getItem('merchant_id'),
      defaultDateToday: '',
      analyticsData: analyticsData,
      apexChatData: apexChartData,
      sessionsByDeviceDonutSeries: [0, 0, 0],
      sessionsByDeviceDonutAnalyticsData: [0, 0, 0, 0],
      lineAreaChartSplineSeries: [0, 0, 0, 0],
      /* mixedChartAlarm: {
           series: [
               {
                   name: this.$t('lastDay'),
                   type: 'column',
                   data: [0]
               },
               {
                   name: this.$t('today'),
                   type: 'column',
                   data: [0]
               }
           ],
           chartOptions: {
               chart: {
                   stacked: false,
               },
               stroke: {
                   width: [0, 0],
                   curve: 'smooth',
               },
               plotOptions: {
                   bar: {
                       columnWidth: '50%'
                   }
               },
               grid: {
                   show: true,
                   borderColor: '#90A4AE',
                   strokeDashArray: 0,
                   position: 'back',
                   xaxis: {
                       lines: {
                           show: false
                       }
                   },
                   yaxis: {
                       lines: {
                           show: false
                       }
                   },
                   row: {
                       colors: undefined,
                       opacity: 0.5
                   },
                   column: {
                       colors: undefined,
                       opacity: 0.5
                   },
                   padding: {
                       top: 0,
                       right: 0,
                       bottom: 0,
                       left: 0
                   },
               },
               fill: {
                   opacity: [0.22, 1],
                   gradient: {
                       inverseColors: true,
                       shade: 'light',
                       type: "vertical",
                       opacityFrom: 1,
                       opacityTo: 1,
                       stops: [0, 100, 100, 100]
                   }
               },
               colors: ['#546E7A', '#40f59c'],
               labels: [''],
               markers: {
                   size: 0
               },
               xaxis: {},
               yaxis: {

                   show: true,
                   title: {
                       text: this.$t('alarmCount')
                   },
                   min: 0,


               },
               tooltip: {
                   forceNiceScale: true,
                   y: {
                       formatter: function (y) {
                           if (typeof y !== "undefined") {
                               return y.toFixed(0) + " alarm";
                           }
                           return y;

                       }
                   }
               }
           }
       },*/
      mixedChartAlarm: {
        series: [{
          name: this.$t('lastDay'),
          data: [0]
        }, {
          name: this.$t('today'),
          data: [0]
        }],
        chartOptions: {
          colors: ['rgba(212,212,212,0.75)', '#40f59c'],
          grid: {
            show: true,
            borderColor: '#90A4AE',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
              lines: {
                show: false
              }
            },
            row: {
              colors: undefined,
              opacity: 0.5
            },
            column: {
              colors: undefined,
              opacity: 0.5
            },
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '50%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 10,
            colors: ['transparent']
          },
          xaxis: {

            categories: [''],
          },
          yaxis: {
            show: false,
            title: {
              text: 'alarm'
            }
          },
          fill: {
            opacity: 1

          },
          tooltip: {
            y: {
              formatter: function (val) {
                return +val + " alarm"
              }
            }
          }
        }
      },
      basketToSalesRate: 0,
      alarmRate: 0,
      alarmBefore: 0,
      alarmLastDay: 0,
      mixedChartPickedUp: {
        series: [
          {
            name: this.$t('lastDay'),
            data: [0]
          },
          {
            name: this.$t('today'),
            data: [0]
          }
        ],
        chartOptions: {
          colors: ['rgba(212,212,212,0.75)', '#f5e858'],
          grid: {
            show: true,
            borderColor: '#90A4AE',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
              lines: {
                show: false
              }
            },
            row: {
              colors: undefined,
              opacity: 0.5
            },
            column: {
              colors: undefined,
              opacity: 0.5
            },
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '50%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 10,
            colors: ['transparent']
          },
          xaxis: {

            categories: [''],
          },
          yaxis: {
            show: false,
            title: {
              text: 'alarm'
            }
          },
          fill: {
            opacity: 1

          },
          tooltip: {
            y: {
              formatter: function (val) {
                return +val + " Picked Up"
              }
            }
          }
        }
      },
      pickedUpRate: 0,
      pickedUpDecreased: false,
      pickedUpIncreased: false,
      RiskProductList: [],
      stockList: [],
      pickedUpLastDay: 0,
      pickedUpBeforeDay: 0,
      mixedChartStock: {
        series: [{
          name: this.$t('lastDay'),
          data: [44]
        }, {
          name: this.$t('today'),
          data: [76]
        }],
        chartOptions: {
          colors: ['rgba(212,212,212,0.75)', '#8e2af5'],
          grid: {
            show: true,
            borderColor: '#90A4AE',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
              lines: {
                show: false
              }
            },
            row: {
              colors: undefined,
              opacity: 0.5
            },
            column: {
              colors: undefined,
              opacity: 0.5
            },
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '50%',
            },

          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 10,
            colors: ['transparent'],


          },
          xaxis: {

            categories: [''],
          },
          yaxis: {
            show: false,
            title: {
              text: 'alarm'
            }
          },
          fill: {
            opacity: 1,


          },
          tooltip: {
            y: {
              formatter: function (val) {
                return +val + " Stok"
              }
            }
          },
          legend: {
            show: true,

          },


        }
      },
      stockRate: 0,
      stockToday: 0,
      stockLastDay: 0,
      byPreviousDayAlarmDays: [],
      totalPickedUp: 0,
      totalPickedUpBeforeDay: 0,
      goalOverviewRadialBar: {
        series: [80],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 110,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: "#bfc5cc",
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: 18,
                  color: '#99a2ac',
                  fontSize: '4rem'
                }
              }
            }
          },
          colors: ['#40f59c'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['#fff852'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            },
          },
          stroke: {
            lineCap: 'round'
          },
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1
            },
          },
        }
      },
      goalOverviewRadialBarInteraction: {
        series: [80],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 110,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: "#bfc5cc",
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: 18,
                  color: '#99a2ac',
                  fontSize: '4rem'
                }
              }
            }
          },
          colors: ['#f511c2'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['#6913ff'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            },
          },
          stroke: {
            lineCap: 'round'
          },
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1
            },
          },
        }
      },
      alarmIncreased: false,
      alarmDecreased: false,
      noChangeAlarm: false,
      reportDay: 0,
      totalMiruCount: '',
      totalScannerCount: '',
      totalSales: 0,
      itemToInteraction: {
        "2": 20,
        "3": 30
      },
      items: [],
      itemsAll: [],
      zoneToInteraction: {},
      zones: [],
      dayIndex: 0,
      today: 0,
      computedDay: []


    }
  },

  mounted() {
    this.StoresList();
    this.MostAlarmedHour();
    this.MostInteractionHour();
    this.MostInteractionItem();
    this.MostInteractionZone();
    this.MostAlarmedItem();
    this.MostAlarmedZone();
    this.ByPreviousDayPickedUp();
    this.ByPreviousDayAlarm();
    this.SalesToBasket();
    this.KoronaOzel();

  },
  watch: {
    selectedMerchant() {
      this.StoresList();
    },

    selectedStore() {
      switch (this.selectedStore) {
        case 1:
          return this.selectedStoreName = "Emaar Media Markt";
        case 8:
          return this.selectedStoreName = "Metropol Media Markt";
        case 5:
          return this.selectedStoreName = "Meydan Media Markt";
        case 33:
          return this.selectedStoreName = "BuyBuddy Bağdat Caddesi";

      }
    },
  },
  components: {
    VueApexCharts,
    analyticsData,
    ChangeTimeDurationDropdown,
    StatisticsCardLine,
    apexChartData,

  },
  computed: {
    defaultSeries() {
      const series = [];

      const times = "00:00,01:00,02:00,03:00,04:00,05:00,06:00,07:00,08:00,09:00,10:00,11:00,12:00,13:00,14:00,15:00,16:00,17:00,18:00,19:00,20:00,21:00,22:00,23:00".split(",");
      const weekDays = "mon,tue,wed,thu,fri,sat,sun".split(",");

      times.forEach((time) => {
        const data = [];
        weekDays.forEach((weekDay) => {
          data.push({
            x: this.$t(weekDay),
            y: 0
          },)
        });
        series.push({
          name: time, data
        })
      });

      return series;

    },
    sessionsByDeviceDonut() {
      return {
        analyticsData: [
          {
            device: this.$t('activeMiru'),
            icon: 'MonitorIcon',
            color: 'success',
            sessionsPercentgae: this.sessionsByDeviceDonutAnalyticsData[0],
            comparedResultPercentage: 2,
          },
          {
            device: this.$t('passiveMiru'),
            icon: 'MonitorIcon',
            color: '#767c82',
            sessionsPercentgae: this.sessionsByDeviceDonutAnalyticsData[1],
            comparedResultPercentage: 8,

          },
          {
            device: this.$t('alarm'),
            icon: 'MonitorIcon',
            color: 'danger',
            sessionsPercentgae: this.sessionsByDeviceDonutAnalyticsData[2],
            comparedResultPercentage: -5,
          },
          {
            device: this.$t('totalMiru'),
            icon: 'MonitorIcon',
            color: 'primary',
            sessionsPercentgae: this.sessionsByDeviceDonutAnalyticsData[3],
            comparedResultPercentage: -5
          },

        ],
        comparedResult: [2, -3, 8],
        series: this.sessionsByDeviceDonutSeries,
        chartOptions: {
          labels: [this.$t('activeMiru'), this.$t('passiveMiru'), this.$t('alarm')],
          dataLabels: {
            enabled: false
          },
          legend: {show: false},
          chart: {
            type: 'donut',
            toolbar: {
              show: false
            }
          },
          stroke: {width: 0},
          colors: ['#5fbd5c', '#767c82', '#EA5455'],
          fill: {
            type: 'gradient',
            gradient: {
              gradientToColors: ['#6ffe7c', '#ced8e3', '#f29292']
            }
          }
        },
      }
    },
  },
  destroyed() {
  },

  methods: {

    createReport() {
      this.MostAlarmedHour();
      this.MostInteractionHour();
      this.MostInteractionItem();
      this.MostInteractionZone();
      this.MostAlarmedItem();
      this.MostAlarmedZone();
      this.ByPreviousDayAlarm();
      this.ByPreviousDayPickedUp();
      this.SalesToBasket();
      this.KoronaOzel();
    },

    StoresList() {


      this.$http.get(`${config.API_BASE}/api/v1/location/country/city/store`, {
            params: {
              merchant_id: this.selectedMerchant,
            },
            paramsSerializer: params => {
              return qs.stringify(params, {arrayFormat: 'repeat'})
            }
          }, this.config
      ).then((response) => {
        this.merchantStoreList = response.data;
      });
    },
    MostAlarmedHour() {
      let date_greater_than = new Date();

      // eslint-disable-next-line no-unused-vars
      const dayIndex = (new Date().getDay() - 1);
      if (dayIndex === 0) {
        this.dayIndex = 7
      } else {
        this.dayIndex = dayIndex
      }
      date_greater_than.setDate(date_greater_than.getDate() - this.dayIndex);
      date_greater_than.setHours(-date_greater_than.getTimezoneOffset() / 60);
      date_greater_than.setMinutes(0);
      date_greater_than.setSeconds(0);
      date_greater_than.setMilliseconds(0);
      let date_lower_than = new Date();
      date_lower_than.setDate(date_lower_than.getDate());
      date_lower_than.setHours(-date_lower_than.getTimezoneOffset() / 60);
      date_lower_than.setMinutes(0);
      date_lower_than.setSeconds(0);
      date_lower_than.setMilliseconds(0);

      const defaultBody = {
        merchant_id: this.selectedMerchant,
        store_id: this.selectedStore,
        start_date: date_greater_than.toISOString(),
        end_date: date_lower_than.toISOString(),
      };

      this.$http.get(`${config.API_BASE}/api/v1/merchant/most_alarmed_hour`,
          {
            params: defaultBody,
            paramsSerializer: params => {
              return qs.stringify(params, {arrayFormat: 'repeat'})
            }
          }, this.config
      ).then((response) => {

        const weekdays = {};

        response.data = Object.values(response.data);
        const values = Object.values(response.data);
        let merged_values = [];
        values.forEach((line) => {
          merged_values = [...merged_values, ...line];
        });


        merged_values.forEach((value) => {
          if (!weekdays[value.weekday]) {
            weekdays[value.weekday] = {};
          }

          const day = weekdays[value.weekday];
          day[(value.hour)] = value.alarm_count;
        });

        const timeZone = (new Date()).getTimezoneOffset() / 60;
        let series = JSON.parse(JSON.stringify(this.defaultSeries));
        Object.keys(weekdays).map((week) => {
          const dayData = weekdays[week];
          Object.keys(dayData).map((hour) => {

            const trHour = (24 + (parseInt(hour) - timeZone)) % 24;
            series[trHour].data[(7 + (week - 1)) % 7].y = dayData[hour];
          })
        });

        series = series.splice(10, 13).reverse();

        this.downloadHours = merged_values.map((item) => {
          if (item.weekday === 0) {
            item.weekday = 'pazar'
          }
          if (item.weekday === 1) {
            item.weekday = 'pazartesi'
          }
          if (item.weekday === 2) {
            item.weekday = 'salı'
          }
          if (item.weekday === 3) {
            item.weekday = 'çarşamba'
          }
          if (item.weekday === 4) {
            item.weekday = 'perşembe'
          }
          if (item.weekday === 5) {
            item.weekday = 'cuma'
          }
          if (item.weekday === 6) {
            item.weekday = 'cumartesi'
          }
          return item
        });
        //console.table(series);
        this.series = [...series];
      })
    },
    MostAlarmedItem() {
      const moment = require('moment');
      let date_greater_than = new Date();
      date_greater_than.setDate(date_greater_than.getDate() - 1);
      date_greater_than.setHours(-date_greater_than.getTimezoneOffset() / 60);
      date_greater_than.setMinutes(0);
      date_greater_than.setSeconds(0);
      date_greater_than.setMilliseconds(0);

      let date_lower_than = new Date();
      date_lower_than.setDate(date_lower_than.getDate());
      date_lower_than.setHours(-date_lower_than.getTimezoneOffset() / 60);
      date_lower_than.setMinutes(0);
      date_lower_than.setSeconds(0);
      date_lower_than.setMilliseconds(0);

      this.reportDay = moment(date_greater_than).format("DD-MM-YYYY")

      const defaultBody = {
        merchant_id: this.selectedMerchant,
        store_id: this.selectedStore,
        start_date: date_greater_than.toISOString(),
        end_date: date_lower_than.toISOString(),
      };


      this.$http.get(`${config.API_BASE}/api/v1/merchant/most_alarmed_item`,
          {
            params: defaultBody,
            paramsSerializer: params => {
              return qs.stringify(params, {arrayFormat: 'repeat'})
            }
          }, this.config
      ).then((response) => {
        this.MostAlarmedItemList = response.data;
        this.MostAlarmedItemList = this.MostAlarmedItemList.map((item) => {
          item.price = '₺';
          return item
        });
      });


    },
    MostAlarmedZone() {

      let date_greater_than = new Date();
      date_greater_than.setDate(date_greater_than.getDate() - 1);
      date_greater_than.setHours(-date_greater_than.getTimezoneOffset() / 60);
      date_greater_than.setMinutes(0);
      date_greater_than.setSeconds(0);
      date_greater_than.setMilliseconds(0);

      let date_lower_than = new Date();
      date_lower_than.setDate(date_lower_than.getDate());
      date_lower_than.setHours(-date_lower_than.getTimezoneOffset() / 60);
      date_lower_than.setMinutes(0);
      date_lower_than.setSeconds(0);
      date_lower_than.setMilliseconds(0);

      const defaultBody = {
        merchant_id: this.selectedMerchant,
        store_id: this.selectedStore,
        start_date: date_greater_than.toISOString(),
        end_date: date_lower_than.toISOString(),
      };


      this.$http.get(`${config.API_BASE}/api/v1/merchant/most_alarmed_zone`,
          {
            params: defaultBody,
            paramsSerializer: params => {
              return qs.stringify(params, {arrayFormat: 'repeat'})
            }
          }, this.config
      ).then((response) => {
        this.MostAlarmedZoneList = response.data;

      });


    },
    MostInteractionZone() {

      let date_greater_than = new Date();
      date_greater_than.setDate(date_greater_than.getDate() - 1);
      date_greater_than.setHours(-date_greater_than.getTimezoneOffset() / 60);
      date_greater_than.setMinutes(0);
      date_greater_than.setSeconds(0);
      date_greater_than.setMilliseconds(0);

      let date_lower_than = new Date();
      date_lower_than.setDate(date_lower_than.getDate());
      date_lower_than.setHours(-date_lower_than.getTimezoneOffset() / 60);
      date_lower_than.setMinutes(0);
      date_lower_than.setSeconds(0);
      date_lower_than.setMilliseconds(0);

      const defaultBody = {
        merchant_id: this.selectedMerchant,
        store_id: this.selectedStore,
        start_date: date_greater_than.toISOString(),
        end_date: date_lower_than.toISOString(),
      };


      this.$http.get(`${config.API_BASE}/api/v1/merchant/most_interaction_zone`,
          {
            params: defaultBody,
            paramsSerializer: params => {
              return qs.stringify(params, {arrayFormat: 'repeat'})
            }
          }, this.config
      ).then((response) => {
        this.MostInteractionZoneList = response.data;
        this.zones = response.data;
        this.zoneToInteraction = response.data.zoneToInteraction

        this.zones.map(item => {

          const interactionValue = this.zoneToInteraction[item.zone_id];

          if (typeof interactionValue === "number") {
            item.interaction = interactionValue;
          }
        });


        this.zones.sort(function (a, b) {
          return b.interaction - a.interaction
        });



      });


    },
    MostInteractionHour() {
      let date_greater_than = new Date();

      // eslint-disable-next-line no-unused-vars
      const dayIndex = (new Date().getDay() - 1);
      if (dayIndex === 0) {
        this.dayIndex = 7
      } else {
        this.dayIndex = dayIndex
      }


      date_greater_than.setDate(date_greater_than.getDate() - this.dayIndex);

      date_greater_than.setHours(-date_greater_than.getTimezoneOffset() / 60);
      date_greater_than.setMinutes(0);
      date_greater_than.setSeconds(0);
      date_greater_than.setMilliseconds(0);

      let date_lower_than = new Date();
      date_lower_than.setDate(date_lower_than.getDate());
      date_lower_than.setHours(-date_lower_than.getTimezoneOffset() / 60);
      date_lower_than.setMinutes(0);
      date_lower_than.setSeconds(0);
      date_lower_than.setMilliseconds(0);

      const defaultBody = {
        merchant_id: this.selectedMerchant,
        store_id: this.selectedStore,
        start_date: date_greater_than.toISOString(),
        end_date: date_lower_than.toISOString(),
      };

      this.$http.get(`${config.API_BASE}/api/v1/merchant/most_interaction_hour`,
          {
            params: defaultBody,
            paramsSerializer: params => {
              return qs.stringify(params, {arrayFormat: 'repeat'})
            }
          }, this.config
      ).then((response) => {
        const weekdays = {};

        response.data = Object.values(response.data);
        const values = Object.values(response.data);
        let merged_values = [];
        values.forEach((line) => {
          merged_values = [...merged_values, ...line];
        });

        merged_values.forEach((value) => {
          if (!weekdays[value.weekday]) {
            weekdays[value.weekday] = {};
          }

          const day = weekdays[value.weekday];
          day[(value.hour)] = value.interaction_count;
        });

        const timeZone = (new Date()).getTimezoneOffset() / 60;
        let series = JSON.parse(JSON.stringify(this.defaultSeries));
        Object.keys(weekdays).map((week) => {
          const dayData = weekdays[week];
          Object.keys(dayData).map((hour) => {

            const trHour = (24 + (parseInt(hour) - timeZone)) % 24;
            series[trHour].data[(7 + (week - 1)) % 7].y = dayData[hour];
          })
        });
        series = series.splice(10, 13).reverse();
        this.downloadHours = merged_values.map((item) => {
          if (item.weekday === 0) {
            item.weekday = 'pazar'
          }
          if (item.weekday === 1) {
            item.weekday = 'pazartesi'
          }
          if (item.weekday === 2) {
            item.weekday = 'salı'
          }
          if (item.weekday === 3) {
            item.weekday = 'çarşamba'
          }
          if (item.weekday === 4) {
            item.weekday = 'perşembe'
          }
          if (item.weekday === 5) {
            item.weekday = 'cuma'
          }
          if (item.weekday === 6) {
            item.weekday = 'cumartesi'
          }
          return item
        });
        this.seriesInteraction = [...series];
      })
    },
    MostInteractionItem() {


      let date_greater_than = new Date();
      date_greater_than.setDate(date_greater_than.getDate() - 1);
      date_greater_than.setHours(-date_greater_than.getTimezoneOffset() / 60);
      date_greater_than.setMinutes(0);
      date_greater_than.setSeconds(0);
      date_greater_than.setMilliseconds(0);

      let date_lower_than = new Date();
      date_lower_than.setDate(date_lower_than.getDate());
      date_lower_than.setHours(-date_lower_than.getTimezoneOffset() / 60);
      date_lower_than.setMinutes(0);
      date_lower_than.setSeconds(0);
      date_lower_than.setMilliseconds(0);

      const defaultBody = {
        merchant_id: this.selectedMerchant,
        store_id: this.selectedStore,
        start_date: date_greater_than.toISOString(),
        end_date: date_lower_than.toISOString(),
      };


      this.$http.get(`${config.API_BASE}/api/v1/merchant/most_interaction_item`,
          {
            params: defaultBody,
            paramsSerializer: params => {
              return qs.stringify(params, {arrayFormat: 'repeat'})
            }
          }, this.config
      ).then((response) => {
        this.items = response.data;


        this.items.sort(function (a, b) {
          return b.interaction_count - a.interaction_count
        });


      });


      this.totalCount = 28;
      this.activeCountAlarm = 20;
      this.passiveCountAlarm = 5;
      this.alarmCountAlarm = 3;
      this.sessionsByDeviceDonutAnalyticsData = [20,
        5,
        3,
        28
      ];
      this.sessionsByDeviceDonutSeries = [
        20,
        5,
        3
      ];


    },
    ByPreviousDayAlarm() {
      this.alarmIncreased = false
      this.alarmDecreased = false
      this.noChangeAlarm = false
      let date_greater_than = new Date();
      date_greater_than.setDate(date_greater_than.getDate() - 2); //burda 2
      date_greater_than.setHours(-date_greater_than.getTimezoneOffset() / 60);
      date_greater_than.setMinutes(0);
      date_greater_than.setSeconds(0);
      date_greater_than.setMilliseconds(0);

      let date_lower_than = new Date();
      date_lower_than.setDate(date_lower_than.getDate());
      date_lower_than.setHours(-date_lower_than.getTimezoneOffset() / 60);
      date_lower_than.setMinutes(0);
      date_lower_than.setSeconds(0);
      date_lower_than.setMilliseconds(0);

      const defaultBody = {
        merchant_id: this.selectedMerchant,
        store_id: this.selectedStore,
        start_date: date_greater_than.toISOString(),
        end_date: date_lower_than.toISOString(),
      };

      this.$http.get(`${config.API_BASE}/api/v1/merchant/store/alarm_rate`,
          {
            params: defaultBody,
            paramsSerializer: params => {
              return qs.stringify(params, {arrayFormat: 'repeat'})
            }
          }, this.config
      ).then((response) => {
        this.today = this.$moment(new Date).format('DD');


        /*  this.computedDay = response.data.days_and_counts.map((item) => {
              if (this.today - 2 === item.day) {
                  this.alarmBefore = item.alarm_count
                  console.log("alarmBefore", this.alarmBefore)
              } else if (this.today - 1 === item.day) {
                  this.alarmLastDay = item.alarm_count
                  console.log("alarmLastDay", this.alarmLastDay)
              }

          })*/


        this.alarmLastDay = response.data.yesterday_alarm_count

        this.alarmBefore = response.data.the_day_before_yesterday_alarm_count


        this.alarmRate = Math.floor(response.data.rate)
        // const series = [...this.mixedChartAlarm.series];


        const lastDay = this.alarmLastDay;
        const today = this.alarmBefore;

        const series = [...this.mixedChartAlarm.series];


        if (Math.sign(this.alarmRate) === -1) {

          this.alarmDecreased = true;
          this.alarmRate = Math.abs(this.alarmRate)

        } else if (Math.sign(this.alarmRate) === 1) {
          this.alarmIncreased = true;
          this.alarmRate = Math.abs(this.alarmRate)

        } else if (Math.sign(this.alarmRate) === 0) {
          this.noChangeAlarm = true;
          this.alarmRate = Math.abs(this.alarmRate)
        }
        series[0].data = [today];
        series[1].data = [lastDay];
        this.mixedChartAlarm = {
          ...this.mixedChartAlarm,
          series
        };
      });


    },
    SalesToBasket() {

      let date_greater_than = new Date();
      date_greater_than.setDate(date_greater_than.getDate() - 1);
      date_greater_than.setHours(-date_greater_than.getTimezoneOffset() / 60);
      date_greater_than.setMinutes(0);
      date_greater_than.setSeconds(0);
      date_greater_than.setMilliseconds(0);
      let date_lower_than = new Date();
      date_lower_than.setDate(date_lower_than.getDate());
      date_lower_than.setHours(-date_lower_than.getTimezoneOffset() / 60);
      date_lower_than.setMinutes(0);
      date_lower_than.setSeconds(0);
      date_lower_than.setMilliseconds(0);

      const defaultBody = {
        merchant_id: this.selectedMerchant,
        store_id: this.selectedStore,
        start_date: date_greater_than.toISOString(),
        end_date: date_lower_than.toISOString(),
      };

      this.$http.get(`${config.API_BASE}/api/v1/merchant/session_funnel`,
          {
            params: defaultBody,
            paramsSerializer: params => {
              return qs.stringify(params, {arrayFormat: 'repeat'})
            }
          }, this.config
      ).then((response) => {
        console.log("sales basket", response.data)
        this.totalSales = response.data.sessions_with_add_to_cart_count
        this.totalPickedUp = response.data.sessions_with_product_count;
        this.basketToSalesRate = Math.floor(response.data.picked_up_to_add_to_basket_rate);
        this.goalOverviewRadialBar.series = [this.basketToSalesRate];


      });

    },
    ByPreviousDayPickedUp() {
      this.pickedUpDecreased = false
      this.pickedUpIncreased = false

      let date_greater_than = new Date();
      date_greater_than.setDate(date_greater_than.getDate() - 2); //burda 2
      date_greater_than.setHours(-date_greater_than.getTimezoneOffset() / 60);
      date_greater_than.setMinutes(0);
      date_greater_than.setSeconds(0);
      date_greater_than.setMilliseconds(0);

      let date_lower_than = new Date();
      date_lower_than.setDate(date_lower_than.getDate());
      date_lower_than.setHours(-date_lower_than.getTimezoneOffset() / 60);
      date_lower_than.setMinutes(0);
      date_lower_than.setSeconds(0);
      date_lower_than.setMilliseconds(0);

      const defaultBody = {
        merchant_id: this.selectedMerchant,
        store_id: this.selectedStore,
        start_date: date_greater_than.toISOString(),
        end_date: date_lower_than.toISOString(),
      };

      this.$http.get(`${config.API_BASE}/api/v1/merchant/store/session_rate`,
          {
            params: defaultBody,
            paramsSerializer: params => {
              return qs.stringify(params, {arrayFormat: 'repeat'})
            }
          }, this.config
      ).then((response) => {
        console.log("previous picked up:", response.data)


        this.byPreviousDayPickedUpDays = response.data.days_and_counts;

        this.pickedUpRate = Math.floor(response.data.rate);
        this.pickedUpLastDay = response.data.days_and_counts[0].session_count;


        if (response.data.days_and_counts[1]) {
          this.pickedUpBeforeDay = response.data.days_and_counts[1].session_count;
        } else {
          this.pickedUpBeforeDay = 0
        }


        const series = [...this.mixedChartPickedUp.series];
        if (Math.sign(this.pickedUpRate) === -1) {
          this.pickedUpDecreased = true;
          this.pickedUpRate = Math.abs(this.pickedUpRate)


        } else if (Math.sign(this.pickedUpRate) === 1) {
          this.pickedUpIncreased = true;
          this.pickedUpRate = Math.abs(this.pickedUpRate)
        }


        this.goalOverviewRadialBarInteraction.series = [this.pickedUpRate];
        series[0].data = [this.pickedUpBeforeDay];
        series[1].data = [this.pickedUpLastDay];
        this.mixedChartPickedUp = {
          ...this.mixedChartPickedUp,
          series
        };
      });


    },
    KoronaOzel() {

      let date_greater_than = new Date();
      date_greater_than.setDate(date_greater_than.getDate() - 1);
      date_greater_than.setHours(-date_greater_than.getTimezoneOffset() / 60);
      date_greater_than.setMinutes(0);
      date_greater_than.setSeconds(0);
      date_greater_than.setMilliseconds(0);
      let date_lower_than = new Date();
      date_lower_than.setDate(date_lower_than.getDate());
      date_lower_than.setHours(-date_lower_than.getTimezoneOffset() / 60);
      date_lower_than.setMinutes(0);
      date_lower_than.setSeconds(0);
      date_lower_than.setMilliseconds(0);

      const defaultBody = {
        merchant_id: this.selectedMerchant,
        store_id: this.selectedStore,
        start_date: date_greater_than.toISOString(),
        end_date: date_lower_than.toISOString(),
      };

      this.$http.get(`${config.API_BASE}/api/v1/merchant/session`,
          {
            params: defaultBody,
            paramsSerializer: params => {
              return qs.stringify(params, {arrayFormat: 'repeat'})
            }
          }, this.config
      ).then((response) => {
        this.itemsAll = response.data;
        console.log("korona", response.data)


      });

    },


  }
}

</script>
<style lang="scss" >
.ReportWrapper {
  width: 90%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  .reportLogo {
    margin: auto;
    padding: 10px;
  }

  .reportTitleBig {
    font-weight: bold;
    text-align: center;
    font-size: 26px;
  }

  .reportSecurity, .reportSecurityStatus, .reportProductPerformance {
    display: flex;
    flex-direction: column;
  }

  .reportTitleM {
    font-weight: bold;
    font-size: 20px;

  }

  .reportTitleDes {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
    width: 600px;
  }

  .chartDes {
    width: 40%;
    color: #707070;

  }

  .chartDesStock {
    width: 60%;
    color: #707070;
    margin-top: 10%;
  }

  .chartDesFont {
    font-size: 15px;
    font-weight: bold;
    color: #969696;
  }

  .reportSecurityTable {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;


  }

  .apexcharts-toolbar {
    display: none;
  }

  .tableWrapper-scroll {
    height: 357px;
    overflow: hidden;
    text-align: center;

    .vs-con-table .vs-con-tbody {
      border: none;
    }
  }
  .tableWrapper {
    text-align: center;

    .vs-con-table .vs-con-tbody {
      border: none;
    }
  }

  .MostCardStyle {
    width: 32%;
    height: 455px;
    margin-bottom: 32px;

    .con-select {
      width: 135px;
    }

    .vxCardTitleClass {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .MostCardStyleMedium {
    width: 48%;
    height: 440px;
    margin-bottom: 32px;

    .con-select {
      width: 135px;
    }

    .vxCardTitleClass {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .MostCardStyle2 {
    width: 66%;
    height: 455px;
    margin-bottom: 32px;

    .con-select {
      width: 135px;
    }

    .vxCardTitleClass {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .MostCardStyle3 {
    width: 100%;
    height: 350px;
    margin-bottom: 32px;

    .con-select {
      width: 135px;
    }

    .vxCardTitleClass {
      font-size: 16px;
      font-weight: bold;
    }
  }

  th .sort-th, th .vs-table-text {
    justify-content: center;
  }

  .topPersonDes {
    text-align: left;

  }

  #topPerson .vx-card__collapsible-content .vx-card__body {
    padding: 5px 20px 0;


  }
}
#topPerson{
  background: #f4f4f4;
  color: #c4c4c4;
}
.yakinda{
  margin-top: 50%;
}
.Limit5Table table tr:nth-child(n+8) {
  display: none;
}

.Limit4Table table tr:nth-child(n+8) {
  display: none;
}
.alarmChartCountLabel, .pickedUpChartCountLabel, .stockChartCountLabel {

  width: 75%;
  display: flex;
  justify-content: center;


  .lastDay {
    color: grey;
    flex: 0.5;
    text-align: right;
  }

  .today {
    flex: 0.5;
    color: black;


  }

}

.vs-table--tbody-table {
  min-width: 0;
}

.vs-table--not-data {
  visibility: hidden;
}

.filterReport {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 0;

}

.storeInfo {
  display: flex;
  flex-direction: column;
  line-height: 30px;
  margin-bottom: 20px;
}

.arrowArea {

}

.arrowArea .material-icons {
  font-size: 100px;
  vertical-align: middle;
  margin-top: -20px;

}

.arrowGreen {
  color: #47f07e;
}

.arrowRed {
  color: #da0412;
}

.rateFont {
  font-weight: bold;
  font-size: 30px;
  margin-top: 13%;
}

.arrowRateWrapper {
  display: flex;
  margin-left: 30%;
  margin-top: 30%;
}

.arrowRateWrapperAlarm {
  display: flex;
  margin-left: 30%;
  margin-top: 20%;
}
.apexcharts-legend-text, .apexcharts-legend-marker {
  visibility: hidden !important;
}

@media print {
  .ReportWrapper {
    zoom: 0.6;

    .reportTitleM {
      padding: 20px 0 0 0;
      page-break-before: always
    }
    .bottomTitle{

    }
  }
  .koronaTitle{
    margin-top: 350px;
  }
  .vx-card {
    box-shadow: none;
    border: 1px solid #d2d2d2;
  }
  .specialTitle {
    font-weight: bold;

  }
  .SpecialArea {

  }
  .filterReport {
    position: absolute;
    visibility: hidden;
  }
  .apexcharts-legend-text, .apexcharts-legend-marker {
    visibility: hidden !important;
  }
  #topPerson{
    border: 1px solid #eeeeee;
    color: #f4f4f4;
  }
  .yakinda{
    margin-top: 50%;
  }
  .vxCardTitleClass{
    cursor: default!important;
  }
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
  width: auto !important;
}
</style>

